import React, { useState, useContext, useEffect } from "react";

import { useRouter } from "next/router";
import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { CartContext } from "context/cart";
import { AppContext } from "context/app";
import { ZipCodeContext } from "context/zipCode";
import { useIsMobile } from "@pepdirect/helpers/useIsMobile";
import { useRouterWithRedirect } from "hooks/useRouterWithRedirect";
import { useGenericOnClickRouter } from "hooks/useGenericOnClickRouter";
import { NavHeader as PepNavHeader } from "@pepdirect/ui/NavHeader";
import { HeaderLocationProps } from "@pepdirect/ui/header/navheader/types";
import { logNavigationClickEvent } from "@pepdirect/helpers/analyticsLogger";
import { endpoints, headerPromotion } from "config";
import { isFeatureFlagEnabled } from "helpers/featureFlags";
import {
  AtdHeaderQuery,
  getSdk,
  HeaderQuery,
} from "services/datocms/generated";
import { createDatoClient } from "services/datocms";
import { useActiveAccountQuery } from "services/graphql/generated";

export function NavHeader(): JSX.Element {
  const router = useRouter();
  const { open } = useContext(ModalContext);
  const { featureFlags } = useContext(AppContext);
  const { currentUserId, logout } = useContext(UserContext);
  const { getSize } = useContext(CartContext);
  const { zipCode } = useContext(ZipCodeContext);
  const { data: activeAccount, refetch: refectActiveAccount } =
    useActiveAccountQuery();
  const size = getSize();
  const { pushPreserveRedirect } = useRouterWithRedirect();
  const { route } = useGenericOnClickRouter();
  const [headerItems, setHeaderItems] = useState<
    NonNullable<HeaderQuery["navigationHeader"]>["headerItems"]
  >([]);
  const [atdHeaderItems, setAtdHeaderItems] = useState<
    NonNullable<AtdHeaderQuery["atdNavigationHeader"]>["headerItems"]
  >([]);

  const { data: accountData, loading: accountLoading } =
    useActiveAccountQuery();

  const isMobile = useIsMobile();

  const [headerKey, setHeaderKey] = useState("h");

  useEffect(() => {
    getSdk(createDatoClient(router?.isPreview))
      .Header()
      .then((res) => {
        setHeaderItems(res?.navigationHeader?.headerItems || []);
      });

    getSdk(createDatoClient(router?.isPreview))
      .AtdHeader()
      .then((res) => {
        setAtdHeaderItems(res?.atdNavigationHeader?.headerItems || []);
      });
  }, [router?.isPreview]);

  useEffect(() => {
    refectActiveAccount();
  }, [currentUserId, refectActiveAccount]);

  const searchData = {
    showSearchBar: false,
    placeholderText: ``,
    onSearchHandler: () => {},
  };

  const goToSignIn = () => {
    if (isMobile) {
      // HACK to reset header, should be removed later
      setHeaderKey(`${headerKey}-1`);
      pushPreserveRedirect("/sign-in");
    } else {
      open("LOGIN_MODAL");
    }
  };

  const logoutAndResetHeader = () => {
    if (isMobile) {
      // HACK to reset header, should be removed later
      setHeaderKey(`${headerKey}-1`);
    }
    logout();
  };

  const userData = {
    isLoggedIn: !!currentUserId,
    accountUrl: `${endpoints.portalFrontend}/dashboard/account/`,
    onLogInClicked: goToSignIn,
    onLogOutClicked: logoutAndResetHeader,
    showATDContact:
      accountData?.activeAccount?.tags?.includes("ATD") && !accountLoading,
  };

  const location: HeaderLocationProps = {
    showLocation:
      (currentUserId && isFeatureFlagEnabled(featureFlags, "geo_location")) ||
      false,
    onClickHandler: () => {
      open("ZIPCODE_MODAL");
    },
    zipCode,
  };

  const linksForLoggedInUser: {
    navHeaderDisplay: string;
    navHeaderLink: string;
  }[] = headerItems.map((item) => ({
    navHeaderDisplay: item.title || "",
    navHeaderLink: item.urlPath || "",
  }));

  const linksForAtdUser: {
    navHeaderDisplay: string;
    navHeaderLink: string;
  }[] = atdHeaderItems?.map((item) => ({
    navHeaderDisplay: item.title || "",
    navHeaderLink: item.urlPath || "",
  }));

  const getHeaderItems = () => {
    if (activeAccount?.activeAccount?.tags?.includes("ATD")) {
      return linksForAtdUser;
    } else {
      return linksForLoggedInUser;
    }
  };

  const headerData = {
    promotion: headerPromotion,
    search: searchData,
    logo: {
      logoImgLink: `${endpoints.cdn}/static-images/pepsico-shop-icons/logo-white.svg`,
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => {
        route(e, currentUserId ? "/home" : "/");
      },
    },
    navBar: {
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => {
        const { innerText } = e.target as HTMLElement;
        logNavigationClickEvent(currentUserId, innerText);
        route(e);
      },
      navItems: currentUserId ? getHeaderItems() : [],
      user: userData,
      searchBar: searchData,
      location,
    },
    shoppingCart: {
      numOfItems: size,
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) =>
        route(e, "/cart"),
    },
    user: userData,
    location,
  };

  return (
    <div className={currentUserId ? "header-logged-in" : "header-logged-out"}>
      <PepNavHeader key={headerKey} {...headerData} />
    </div>
  );
}
