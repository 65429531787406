import { useState } from "react";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { UserContextInterface } from "types/user";
import {
  CurrentUserQuery,
  useCurrentUserQuery,
  useLogoutUserMutation,
} from "services/graphql/generated";

export function useUserProvider(
  user: CurrentUserQuery["currentUser"] | null,
  client: ApolloClient<NormalizedCacheObject>
): {
  userContextValue: UserContextInterface;
} {
  const [currentUserId, setCurrentUserId] = useState<string | null>(
    user?.id || null
  );
  // We have this query here because when wellspace-token is
  // broken, we cannot remove it from the browser because
  // it is a http-only cookie. So we let the backend clear it by this query.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userQuery = useCurrentUserQuery({ client });
  const [logoutUser] = useLogoutUserMutation({ client });

  const handleLogout = async () => {
    try {
      await logoutUser();
    } catch (e) {
      // logged in errorLink
    } finally {
      setCurrentUserId(null);
      // for some unknown reason, router.push("/") destroys the
      // react-select styles, so using the below as a workaround
      // https://pepsico-ecomm.atlassian.net/browse/B2B-1949
      window.location.pathname = "/";
    }
  };

  const userContextValue: UserContextInterface = {
    currentUserId,
    logout: handleLogout,
    setUser: (userId: string) => {
      setCurrentUserId(userId);
    },
  };

  return { userContextValue };
}
